
import Experiencebanner from '../images/Experiencebanner.png';
import AboutHeading from '../images/about-graphics-head.png';
import CarouselDesign from '../components/md-bootstrap-carousel-design.js';
import { Helmet } from 'react-helmet-async';

const GraphicSlide = () => {
    return (
        <div className="sub graphic-slides">
            <Helmet>
                <title>Graphic Design Slideshow- B.Crowe Design</title>
                <meta name="Graphic Design Slideshow- B.Crowe Design" content="BCrowe.net- Graphic Design Slideshow" />
            </Helmet>
            <div className="top-Z-Proj-tile"></div>
            <div className="mid-Z-Proj-tilePrint">
                <div id="background-wrap">
                    <div class="x1">
                        <div class="cloud"></div>
                    </div>
                    <div class="x2">
                        <div class="cloud"></div>
                    </div>
                    <div class="x3">
                        <div class="cloud"></div>
                    </div>
                    <div class="x4">
                        <div class="cloud"></div>
                    </div>

                    <div class="x5">
                        <div class="cloud"></div>
                    </div>
                </div>
            </div>
            <div className="bot-Z-Proj-tile"></div>
            <div id="mainWrapper">
                <div id="projHolder">
                    <div id="loopedSlider">
                        <div className="slides">
                            <CarouselDesign />
                        </div>
                    </div>
                </div>
                <div id="copyFrame" class="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile">
                            <div className="subcopyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div class="content">
                            <div id="heading-div">
                                <img className="img-fluid" src={AboutHeading}
                                    alt="graphical services: illustration, signange, print work" />
                                    <h1 className='sr-only'>Graphic Projects Slideshow</h1>
                                <br />
                                {/* <a href="graphic-slides">Slide View</a> |
                                <a href="graphic-design">List View</a> */}
                            </div>
                            <div class="blurbage">
                                Graphic design services provide creative solutions for brand identity, marketing packages,
                                brochures, CD/DVD designs, and interactive media. From print to web, all media and marketing
                                campaigns are aimed at acheiving and maintaining cohesive synergy and a consistant message.
                            </div>
                            <br />

                            <div className='serviceBullets'>
                                <div className='row'>
                                    <div className='col-6'>
                                    <ul>
                                            <li>Billboards</li>
                                            <li>Business Cards</li>
                                            <li>Brochures</li>
                                            <li>CD/DVD</li>
                                            <li>Letterhead</li>
                                        </ul>
                                    </div>
                                    <div className='col-6'>
                                        <ul>
                                            <li>Logo Design</li>
                                            <li>Marketing Packages</li>
                                            <li>Signs</li>
                                            <li>Trade Show Solutions</li>
                                            <li>Web Banners</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <table cellpadding="0" cellspacing="0" class="serviceBullets">
                                <tr>
                                    <td colspan="2" class="aboutSkillsTop"></td>
                                </tr>
                                <tr>
                                    <td width="311" class="aboutSkillsTileLeft">

                                        <ul>
                                            <li>Billboards</li>
                                            <li>Business Cards</li>
                                            <li>Brochures</li>
                                            <li>CD/DVD</li>
                                            <li>Letterhead</li>
                                        </ul>
                                    </td>

                                    <td width="315">
                                        <ul>
                                            <li>Logo Design</li>
                                            <li>Marketing Packages</li>
                                            <li>Signs</li>
                                            <li>Trade Show Solutions</li>
                                            <li>Web Banners</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="aboutSkillsBot"></td>
                                </tr>
                            </table> */}
                        </div>
                    </div>

                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile">
                            <div className="copyBotRC"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphicSlide;