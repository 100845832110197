
import React, { useState, useEffect } from 'react';
import Experiencebanner from '../images/Experiencebanner.png';
import MobileExperience from '../images/MobileExperienceBanner.png';
import ExperienceHeading from '../images/exp-head.png';
import { Helmet } from 'react-helmet-async';
import DocIcon from '../images/doc-icon.png';
import ModalResume from '../components/modal-from-scratch/resume-modal.js';

const Experience = () => {
    const [modalResumeState, setModalResumeState] = useState(false);
    function openResumeModal() {
        setModalResumeState(!modalResumeState)
    }

    return (
        <>
        <div className="sub">
            <Helmet>
                <title>Experience, List of Skills- B.Crowe Design</title>
                <meta name="BCrowe.net- Experience, List of Skills" content="BCrowe.net- Experience, List of Skills" />
            </Helmet>
            <div className="top-Z-Sub-tile"></div>
            <div className="mid-Z-Sub-tile"></div>
            <div className="bot-Z-Sub-tile"></div>
            <div class="bannerHolder experience">
                <img src={MobileExperience} className="d-md-none d-lg-none img-fluid" />
            </div>
            <div id="mainWrapper">
                <div id="copyFrame" class="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile">
                            <div className="subcopyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div className="content">
                            <div id="heading-div">
                                <img className="img-fluid" src={ExperienceHeading} alt="summary of skills, front-end web, seo, graphics, illustration, signange, print work" />
                            </div>
                            <div className="blurbage">
                                <a href="#" className="togglemodal" onClick={openResumeModal}>
                                    <img src={DocIcon} alt="Resume" /> Preview Resume 
                                </a> 
                                Creative interactive designer/developer with skills and talents in print
                                design, web design, branding identity, web marketing, SEO, interface design, front-end web development and
                                other interactive media. 20 years experience within a diverse spectrum has built a thorough
                                understanding of the proper workflow and concepts from design to front-end development. </div>
                            <div className='serviceBullets experience'>
                                <h2>
                                    <strong>Scientific Games- Alpharetta, GA</strong><br />
                                    <span><strong>10/2012-06/2024</strong></span>
                                </h2>

                                <ul>
                                    <li>Frontend Development of micro-sites for various state lottery 2nd chance scratch-off promotions</li>
                                    <li>Graphical editing and implementation of assets for web apps</li>
                                    <li>Web banners</li>
                                    <li>Maintenance and content management for multiple state jurisdictions</li>
                                    <li>External React Component library consolidation</li>
                                    <li>Implementation of central CSS template for multiple jurisdictions using the Ensemble Platform</li>
                                </ul>
                                <h2>
                                    <strong>Chimney Solutions Inc.- Alpharetta, GA</strong><br />
                                    <span><strong>Contract Work, Full and Part time: 06/05-02/14</strong></span>
                                </h2>

                                <h3><strong>Industrial Access- Industrial Division &amp; Chimney Solutions- Residential Division</strong></h3>
                                <ul>
                                    <li>Created various print media: brochures, information packs, t-shirts, billboards branded merchandise</li>
                                    <li>Created logo and branding for Industrial Access</li>
                                    <li>Developed websites,  SEO landing pages</li>
                                    <li>Interactive media for internet and trade shows</li>
                                </ul>

                                <h2>
                                    <strong>America's Home Place- Gainesville, GA</strong>
                                    <strong>10/05-12/08, 04/10-07/12</strong>
                                </h2>
                                <div className="row">
                                    <div className='col-6'>
                                        <h3><strong>Marketing Dept. 10/05- 05/07</strong></h3>
                                        <ul>
                                            <li>Designed and developed mid 2000's iteration of public web site</li>
                                            <li>Designed Flash touchscreen kiosk</li>
                                            <li>Created web banners & graphics</li>
                                            <li>Illustrated landscapes for 3D house renderings via composite imaging</li>
                                            <li>Correspondence, design and management of 3rd party web marketing accounts</li>
                                        </ul>
                                    </div>
                                    <div className='col-6'>
                                        <h3><strong>I.T. Dept. 05/07-12/08, 04/10-07/12</strong></h3>
                                        <ul>
                                            <li>Coordinated between I.T. & Marketing on various projects</li>
                                            <li>Designed/developed multiple interfaces, sites</li>
                                            <li>Developed front-end redesign of intranet</li>
                                            <li>Created web marketing landing pages</li>
                                            <li>Search Engine Optimization</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='serviceBullets experience'>
                                <div className="row">
                                    <div className='col-6'>
                                        <ul>
                                            <li>Adobe Illustrator</li>
                                            <li>Adobe Photoshop</li>
                                            <li>Adobe XD</li>
                                            <li>Adobe Animate/Flash</li>
                                            <li>Figma</li>
                                            <li>Visual Studio Code</li>
                                            <li>Adobe After Effects</li>
                                        </ul>
                                    </div>
                                    <div className='col-6'>
                                        <ul>
                                            <li>React.js</li>
                                            <li>CSS/SCSS</li>
                                            <li>HTML5/Canvas</li>
                                            <li>JQuery</li>
                                            <li>SEO</li>
                                            <li>PHP (Front-end)</li>
                                            <li>Git</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile">
                            <div className="copyBotRC"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <ModalResume toggle={modalResumeState} action={openResumeModal} />
        </>
    )
}

export default Experience;