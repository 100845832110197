import React from 'react';
import { useMediaQuery } from 'react-responsive';
const HomePageAnimation2 = () => {
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 953px)'
})

const isTabletOrMobile = useMediaQuery({ query: '(max-width: 952px)' })

return <div>
  { 
    isDesktopOrLaptop && <div id="flashHolder">
        <iframe className="desktop-animation"
          src="https://bcrowe.net/adobe/portfolio-home-page.html"
          //TEST Local  src="../adobe/portfolio-home-page.html"
        ></iframe>
      </div>
  }
  {
    isTabletOrMobile && <div className="mobFlashHolder">
        <iframe
          src="https://bcrowe.net/adobe/mobile/mob-portfolio-home-page.html"
          //TEST Local  src="../adobe/mobile/mob-portfolio-home-page.html"
        ></iframe>
      </div>
  }
</div>
}

export default HomePageAnimation2;